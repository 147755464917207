import React, { Component } from "react";
import Footer from '../../global/Footer';

class Gallery extends Component {
    render() {
        return (
            <p> Gallery</p>
        );
    }
}

export default Gallery;