import React, { Component } from "react";

class Blog extends Component {
  render() {
    return (
        <div> 
            Blog
        </div>
    );
  }
}

export default Blog;